import React from 'react';
import logo from './assets/images/stack_zephyr.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
        <a
          className="App-link"
          href="mailto:bob@zephyrcreations.com"
        > 
          GET IN TOUCH WITH<br/> BOB VAN UNNIK
        </a>
      </header>
    </div>
  );
}

export default App;
